<template>
  <div class="slider-item card tile tile-tv" :class="'uuid-' + uuid">
    <ImageLazy :src="poster" :err="posterError" :alt="title" />

    <div class="card-channel" v-if="data.channel">
      <span class="channel-logo" :title="data.channel.name || ''">
        <img
          :src="data.channel.logo"
          class="img-logo"
          :alt="data.channel.name || ''"
          width="40"
          height="40"
        />
      </span>
    </div>

    <ProgressBar
      v-if="showProgress && progressType === 'seek'"
      :end="data.duration * 60"
      :position="data.seekTime || 0"
    />
    <ProgressBarLive
      v-if="showProgress && progressType === 'live'"
      :start="data.startHourUnixTime"
      :end="data.endHourUnixTime"
    />

    <div class="card-content">
      <div class="card-img-overlay">
        <div class="card-options-top">
          <a
            v-if="$listeners.info"
            v-on:click.prevent="$emit('info')"
            href="#more-info"
            class="card-action-secondary btn-showmodal"
            title="Más información"
          >
            <i class="zmdi zmdi-info-outline"></i>
          </a>

          <a
            v-if="$listeners.delete"
            v-on:click.prevent="$emit('delete')"
            href="#delete"
            class="card-action-secondary"
            title="Eliminar grabación"
          >
            <i class="zmdi zmdi-delete"></i>
          </a>
        </div>

        <a
          v-if="$listeners.play"
          v-on:click.prevent="onPlay"
          href="#play-this"
          title="Reproducir esto"
          class="btn btn-play"
        >
          <i class="zmdi zmdi-play" aria-hidden="true"></i>
        </a>

        <a
          v-if="$listeners.info"
          v-on:click.prevent="$emit('info')"
          href="#more-info"
          class="more-info-all"
          title="Más información"
        ></a>
      </div>
    </div>

    <div class="card-footer">
      <template v-if="contentType === 'RECORD'">
        <div class="card-chapter-channel-number">
          <strong class="card-chapter-channel-number-input"
            >CH {{ data.channel.lcn }}</strong
          >
        </div>
      </template>

      <h5 class="card-title">{{ title }}</h5>
      <p
        class="card-text card-text--meta"
        v-if="progressType === 'live' || data.meta"
      >
        <span class="program-time">{{ data.meta }}</span>
        <span v-if="progressType === 'live'" class="live-indicator">
          <i class="zmdi zmdi-circle"></i>
          <span class="pulse"></span>
          en vivo
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import IMG_NO_POSTER_16_9 from "@/assets/images/no-poster-16-9.png";

const IMG_DEFAULT_ALPHA =
  "a5434fa604af965aa91eb3e32c523ae2f383bfdf6f6cbaa5682ed36a60b5b33e";

export default {
  name: "CardChapter",

  inject: ["getPlayer"],

  components: {
    ImageLazy: () => import("@/components/Image-lazy.vue"),
    ProgressBar: () => import("@/components/Progress-bar.vue"),
    ProgressBarLive: () => import("@/components/Progress-bar-live.vue"),
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    showProgress: {
      type: Boolean,
      required: false,
      default: true,
    },
    componentOrigin: {
      type: String,
      required: false,
      default: "",
    },
    contentType: String,
    zapflag: { type: Boolean, default: false },
    sliderId: {
      type: String,
      default: "",
      required: false,
    },
    sliderTitle: {
      type: String,
      default: "",
      required: false,
    },
    cardId: {
      type: String,
      default: 0,
      required: false,
    },
  },

  data() {
    return {
      uuid: tplay.generateUUID(),
      blockByParentalControl: false,
      oldValueParentalCOntrol: false,
      cpChangedStatus: 1
    };
  },

  mounted() {
    // Control del control parental
    this.blockByParentalControl =
      telecentro.tplay.core.biz.parentalControl.blockAsset(this.data);

    this.$bus.$on("parentalControl.state_changed", this.updateParentalControl);
  },

  beforeDestroy() {
    this.$bus.$off("parentalControl.state_changed", this.updateParentalControl);
  },


  methods: {
    updateParentalControl() {
      //console.log('parentalControl.state_changed',this.cpChangedStatus);
      this.cpChangedStatus++;
    },
    onPlay() {
      let self = this;

      tplay.sendGrafanaEvent(
        tplay.grafana.event.CARD,
        {
          rail_id: this.sliderId,
          rail_title: this.sliderTitle,
          card_id: this.cardId,
          screen: this.$route.name,
        },
        tplay.grafana.actions.ENTER
      );

      if (self.blockByParentalControl) {
        self.$bus.$emit("modal-pin:show", function () {
          if (gtag) {
            gtag("event", "Reproducir desde card", {
              event_category: "CARD-CHAPTER",
              event_action: "Reproducir desde card",
              event_label: "[ " + self.data.channel.name + " ] " + self.title,
              value: "0",
            });
          }
          self.$emit("play");
          tplay.sendAudience(self.data, self.componentOrigin);
        });
      } else {
        if (gtag) {
          gtag("event", "Reproducir desde card", {
            event_category: "CARD-CHAPTER",
            event_action: "Reproducir desde card",
            event_label: "[ " + this.data.channel.name + " ] " + this.title,
            value: "0",
          });
        }
        this.$emit("play");
        tplay.sendAudience(self.data, self.componentOrigin);
      }
      document.body.style.pointerEvents = 'auto';
    },
  },

  computed: {
    poster() {
      const urlFromAlpha = this.data.poster.split("/").pop();

      if (this.blockByParentalControl || urlFromAlpha === IMG_DEFAULT_ALPHA) {
        return IMG_NO_POSTER_16_9;
      } else {
        return this.data.poster ? this.data.poster : IMG_NO_POSTER_16_9;
      }
    },

    posterError() {
      return IMG_NO_POSTER_16_9;
    },

    progressType() {
      const estaEnRango = function (valor, minimo, maximo) {
        return minimo <= valor && valor <= maximo;
      };

      if (
        typeof this.data.startHourUnixTime !== "undefined" &&
        typeof this.data.endHourUnixTime !== "undefined"
      ) {
        if (
          estaEnRango(
            Date.now() / 1000,
            this.data.startHourUnixTime,
            this.data.endHourUnixTime
          )
        ) {
          return "live";
        }
      } else if (typeof this.data.duration !== "undefined") {
        return "seek";
      }

      return "";
    },

    title() {
      if (this.blockByParentalControl) {
        return "Programa bloqueado";
      } else {
        const te = [
          this.data.n_temporada ? "T" + this.data.n_temporada : null,
          this.data.n_episodio ? "E" + this.data.n_episodio : null,
        ]
          .filter((x) => x)
          .join(":");

        return (
          te +
          " " +
          telecentro.tplay.core.helpers.string.replaceUnicode(
            this.data.title.trim()
          )
        );
      }
    },
  },

  watch: {
    // blockByParentalControl:function(newValue, oldValue){
    //     localStorage.setItem('oldvalueParentalControl',oldValue);
    //     localStorage.setItem('newvalueParentalControl',newValue);
    //     if (oldValue && oldValue === false) {
    //         this.oldValueParentalCOntrol = true;
    //     } else {
    //         this.oldValueParentalCOntrol = false;
    //     }
    // },
    // zapflag:function(newvalue, oldvalue){
    //     if (newvalue === true) {
    //         if (this.oldValueParentalCOntrol === true) {
    //             this.blockByParentalControl = localStorage.getItem('oldvalueParentalControl');
    //         } else {
    //             this.blockByParentalControl = localStorage.getItem('newvalueParentalControl');
    //         }
    //     }
    // }
    cpChangedStatus: function(newValue, oldValue){
      this.blockByParentalControl =
      telecentro.tplay.core.biz.parentalControl.blockAsset(this.data);
    } 
  },
};
</script>

